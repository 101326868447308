<template>
    <div class="wrap">
        <mydyList :list="list"></mydyList>
        <div class="page-box" v-show="total">
            <el-pagination :pager-count="5" :page-size="7" @current-change="changePage" background layout="prev, pager, next" :total="total"></el-pagination>
        </div>
    </div>
</template>
<script>
    import mydyList from "@components/common/mydy-list";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {
            mydyList,
        },
        data() {
            return {
                list: [],
                total: 0,
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData(1);
        },
        methods: {
            changePage(s) {
                this.getData(s);
            },
            // 获取列表
            async getData(page) {
                let myid = this.$y_getKey('userid');
                let $r = this.$route.query;
                let uid = $r.uuid || myid;
                let param = {
                    userid: uid,
                    user_id: myid,
                    type: $r.type || 0,
                    longitude: this.$y_getKey('lon'),
                    latitude: this.$y_getKey('lat'),
                    pagesize: 7
                };
                param.page = page || 1;
                let {
                    list,
                    total
                } = await this.$y_list('api/dymanic/lists', [], param);
                this.list = list;
                if (total != -1) {
                    this.total = total;
                }
            },
        },
        computed: {
            ...mapGetters(["s_getUser"]),
        },
        watch: {
            $route() {
                this.getData(1);
            },
            s_getUser() {
                this.getData(1);
            },
        },
    };
</script>

<style lang="less" scoped>

</style>